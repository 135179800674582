//常量
//模块控制模块code
export const codes = {
  workOrder: 'aec92c0dc26f18774d50ef6df33b8ed2', //工单管理
  tenementMange: 'd62394d9440b01ad71dfda6def6b8b54', //住户管理
  payment: 'e166daa91de5bee314e1b1651f02f99b', //缴费管理
  assetManage: 'df2be5977e28b3bd82b58035442b563f', //资产管理
  dashboards: 'f440cf3a3e1e7ae866c8a73ff237bbf5', //仪表管理
  dunning: 'a43d8faffaf3ff9d7a160c41f470f966', //催费管理
  message: '60b7094b80712244a4d4b16503a789bc', //消息推送管理
  material: 'c070a397b31f01aff80638f8afec2dfa', //素材库管理
  label: 'c0f7d27656fcfdacd13d6bb80992166b', //标签管理
  words: '67255865774dfddc42b4f800ac75af28', //话术库管理
  demand: 'b142f91e400523dfbb62e53f39adfc8c', //需求意愿度管理
  facility: 'f6382eca5ebd160fd5253589bdd726ae', //设备管理
  doorControl: 'ad594828d95db2fddc6ebeecf008b58e', //门禁管理
  popularFeelings: '3afae102a5309954fb9ce38934b62f34' //舆情管理
}
