import { ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useLoginStatus } from './sys'
import { useThirdAuth } from './third-auth'
import { useUser } from '@/hooks'
import { addAppUsedRecord } from '@/apis'
// const REDIRECT_TYPE = {
//   SYSTEM: '0', // 系统
//   XLINK: '1' // 云智易
// }
export const useAppClick = () => {
  // const CHILD_SYSTEM_URL = process.env.VUE_APP_CHILD_SYSTEM_URL
  const CHILD_SYSTEM_URL = window.origin
  console.log('CHILD_SYSTEM_URL: ', CHILD_SYSTEM_URL)
  const { getLoginStatus } = useLoginStatus()
  const router = useRouter()
  const route = useRoute()
  const { user } = useUser()
  const { getThirdUrl } = useThirdAuth()
  const appClick = async app => {
    console.log('app: ', app)
    const { id, redirectType, entry } = app
    console.log('entry: ', entry)
    let toPage = entry
    let userInfo = localStorage.getItem('kht-user-info') ? JSON.parse(localStorage.getItem('kht-user-info')) : {}
    console.log('userInfo: ', userInfo)
    // 检测登录状态
    if (await getLoginStatus()) {
      switch (redirectType) {
        case '0':
          // 跳转老应用
          window.open(
            `${CHILD_SYSTEM_URL}/customer_admin/login.html?menuId=${id}&state=8_${user.value.corpid || ''}`,
            '_blank'
          )
          break
        case '1': // 如果是云智易
          // eslint-disable-next-line no-case-declarations
          const url = await getThirdUrl()
          window.open(url, '_blank')
          break
        case '2':
          if (entry.includes('/web-customer-questionnaire')) {
            toPage = `${CHILD_SYSTEM_URL}/${toPage}?userName=${userInfo.username}&token=${userInfo.sessionId}`
          }
          window.open(toPage, '_blank')
          break
      }
      // 如果是云智易
      // if (REDIRECT_TYPE.XLINK === redirectType) {
      //   const url = await getThirdUrl()
      //   window.open(url, '_blank')
      // } else {
      //   // 跳转老应用
      //   window.open(`${CHILD_SYSTEM_URL}/login.html?menuId=${id}&state=8_${user.value.corpid || ''}`, '_blank')
      // }
      await addAppUsedRecord(id)
    } else {
      ElMessageBox.confirm('登录信息失效, 请重新登录', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showCancelButton: false
      })
        .then(() => {
          router.replace({ name: 'Login', query: { redirct: route.fullPath } })
        })
        .catch(() => {})
    }
  }
  return appClick
}
