import axios from 'axios'
import http from '@/common/http'

/*
 * @Author: mrchen
 * @Date: 2021-07-16 10:32:29
 * @Last Modified by: mrchen
 * @Last Modified time: 2021-07-17 10:59:09
 * 第三方认证API
 */

/**
 * 获取第三方配置信息 返回 corp_id, userId, accessToken
 * @returns
 */
export function getThirdSetting() {
  return http.post('..')
}

/**
 * 第三方登录
 * @param {*} corpId // 对面设置的loginId
 * @param {*} userId // 用户ID
 * @param {*} accessToken // accessToken
 * @param {*} source 默认2
 * @returns
 */
export function thirdLogin(corpId, userId, accessToken, source = 2) {
  console.log('process.env.ENV: ', process.env)
  return axios.post(
    `${
      ['http://localhost:9898', 'localhost'].includes(location.origin)
        ? '/thirdLogin'
        : process.env.VUE_APP_YZY_API_BASE
    }/v2/corp-auth-third`,
    {
      corp_id: corpId,
      open_id: userId,
      access_token: accessToken,
      source: source
    }
  )
}
