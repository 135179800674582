/*
 * @Author: mrchen
 * @Date: 2021-07-16 10:27:07
 * @Last Modified by: mrchen
 * @Last Modified time: 2021-07-29 14:50:04
 * 第三方认证相关的
 */

import { thirdLogin } from '@/apis/third-auth'
import { useUser } from '.'

export const useThirdAuth = () => {
  const { user } = useUser()
  // 登录
  const loginOauth = async () => {
    try {
      const setting = {
        corpId: process.env.VUE_APP_YZY_CORP,
        userId: user.value.uuid,
        accessToken: user.value.sessionId
      }
      const result = await thirdLogin(setting.corpId, setting.userId, setting.accessToken)
      if (result.status !== 200) {
        return Promise.reject(result.statusText)
      }
      return result.data
    } catch (error) {
      console.log('error', error)
      return Promise.reject(new Error('loginOauth error'))
    }
  }
  const urlSplice = params => {
    const host = process.env.VUE_APP_YZY_BASE
    const { access_token, refresh_token, member_id, expire_in } = params
    const redirct_url = encodeURIComponent(`${host}/#/home`)
    const timestamp = Date.now()
    return `${host}/verify.html?access_token=${access_token}&refresh_token=${refresh_token}&member_id=${member_id}&timestamp=${timestamp}&expire_in=${expire_in}&redirect_url=${redirct_url}`
  }
  // 获取跳转url
  const getThirdUrl = async () => {
    try {
      const result = await loginOauth()
      console.log('result: ', result)
      return Promise.resolve(urlSplice(result))
    } catch (error) {
      return Promise.reject(new Error('getThirdUrl error'))
    }
  }
  return { getThirdUrl }
}
