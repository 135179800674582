import { reactive, computed, ref } from 'vue'
import { getApps, getCommonlyApps } from '@/apis'
import { MODEL_OPEN_LIST } from '@/store/types'
import { codes } from '@/constants/index'
import { useStore } from 'vuex'
/**
 * 应用管理
 * @param {*} init
 * @returns
 */
export const useApplication = (init = false) => {
  const store = useStore()
  const state = reactive({
    appList: [],
    loadingState: false
  })
  const apps = computed(() => {
    return state.appList.reduce((list, item) => list.concat(item.apps), [])
  })
  const initApps = async () => {
    state.loadingState = true
    try {
      const result = await getApps()
      console.log('result', result)
      state.appList = result.data
      state.loadingState = false
      if (state.appList.length > 0) {
        let appList = state.appList.filter(c => c.id === '0')
        console.log('appList: ', appList)
        if (appList[0].length === 0) {
          return
        }
        let flag = appList[0].apps.some(c => codes.payment === c.module_id)
        console.log('flag: ', flag)
        store.commit(MODEL_OPEN_LIST, flag)
      }
    } catch (error) {
      console.log('initApps error', error)
      state.loadingState = false
    }
  }
  init && initApps()
  return { state, initApps, apps }
}

/**
 * 常用应用
 * @param {*} init
 */
export const useCommonlyApps = (init = false) => {
  const appList = ref([])
  const loadingState = ref(false)
  const store = useStore()
  const initCommonlyApps = async () => {
    loadingState.value = true
    try {
      const result = await getCommonlyApps()
      console.log('2333333', result)
      appList.value = (result.data || []).map(item => item.menu)
      if (appList.value) {
        let flag = appList.value.some(c => codes.payment === c.module_id)
        console.log('flag: ', flag)
        store.commit(MODEL_OPEN_LIST, flag)
      }
      setTimeout(() => {
        loadingState.value = false
      }, 300)
    } catch (error) {
      setTimeout(() => {
        loadingState.value = false
      }, 300)
      console.error('initCommonlyApps error', error)
    }
  }
  init && initCommonlyApps()
  return { appList, loadingState }
}
