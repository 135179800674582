<template>
  <div class="applications-wrapper" v-loading="state.loadingState">
    <div class="session-wrapper" v-for="group in state.appList" :key="group.id">
      <div class="title" v-if="titleShow(group)">{{ group.groupName }}</div>
      <div class="app-items">
        <template v-for="app in group.apps" :key="app.id">
          <div v-if="SETTING_ID !== app.id" class="app-item" @click="appClick(app)" :class="{ disabled: !app.show }">
            <div class="icon-wrap">
              <!-- <i class="iconfont icon-kefu"></i> -->
              <img :src="$getShowUrl(app.fileId)" alt="" />
            </div>
            <div class="app-name">{{ app.name }}</div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="state.appList.length === 0" class="app-null">没有可访问的应用</div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useApplication } from '@/hooks/apps'
import { useAppClick } from '@/hooks/app-click'
export default defineComponent({
  name: 'applications',
  setup() {
    // 设置的菜单

    const SETTING_ID = '04d32d1188cc467eb7a6f363bb23760a'
    const { state } = useApplication(true)
    console.log('state: ', state)

    const appClick = useAppClick()

    const titleShow = item => {
      //标题显示（没有子应用模块显示时标题也隐藏）
      let flag = true
      if (item.apps.length === 0) {
        flag = false
      } else {
        flag = item.apps.length > 0
      }
      return flag
    }
    return { state, appClick, SETTING_ID, titleShow }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
